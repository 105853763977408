
import { Options, Vue } from 'vue-class-component';
import { Right } from '@icon-park/vue-next';

@Options({
    components: { Right },
    props: {
        btnText: {
            type: String,
            default: ''
        },
        btnType: {
            type: String,
            default: 'primary'
        },
        btnIcon: {
            type: String,
            default: 'n-add-bold'
        }
    },
    emits: ['btn-click'],
    data(){
        return{
            matched: [],
            meta: {}
        }
    },
    mounted(){
        this.matched = this.$route.matched
        this.meta = this.$route.meta
    }
})

export default class Breadcrumb extends Vue {}
