<template>
    <div class="page-header">
        <div class="left">
            <h2>{{ meta.title }}</h2>
            <div class="breadcrumb">
                <template v-for="(item, index) in matched" :key="index">
                    <router-link class="link" :to="item.path" v-if="index === 0">主页</router-link>
                    <router-link class="link" :to="item.path" v-else-if="index !== matched.length - 1">{{ item.meta.title }}</router-link>
                    <span class="text" v-else>{{ item.meta.title }}</span>
                    <right v-if="index !== matched.length - 1" class="icon" theme="outline" size="14" fill="#ccc"/>
                </template>
            </div>
        </div>
        <div class="right" v-if="btnText !== ''">
            <n-button size="large" :type="btnType" :prefix="btnIcon" @click="$emit('btn-click', $event)">{{ btnText }}</n-button>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Right } from '@icon-park/vue-next';

@Options({
    components: { Right },
    props: {
        btnText: {
            type: String,
            default: ''
        },
        btnType: {
            type: String,
            default: 'primary'
        },
        btnIcon: {
            type: String,
            default: 'n-add-bold'
        }
    },
    emits: ['btn-click'],
    data(){
        return{
            matched: [],
            meta: {}
        }
    },
    mounted(){
        this.matched = this.$route.matched
        this.meta = this.$route.meta
    }
})

export default class Breadcrumb extends Vue {}
</script>

<style lang="scss" scoped>
.page-header{
    @extend .flex-row-between;
    margin: 15px;
    .left{
        h2{
            margin: 0;
            font-size: 22px;
            font-weight: normal;
        }
        .breadcrumb{
            @extend .flex-row;
            margin-top: 10px;
            .link{
                color: $text-color;
                &:hover{
                    color: $primary;
                }
            }
            .icon{
                @extend .flex-column-middle;
            }
            .text{
                color: $info;
            }
        }
    }
    .right{
        @extend .flex-column-middle;
    }
}
</style>